import Head from 'next/head';
import { useRouter } from 'next/router';
import { memo } from 'react';

import { isTruthy } from '@lib/api/is-truthy';
import { publicEnv } from '@lib/env/public-env-vars';

import { useWebPageMetadataQuery } from '../../../core/graphql/queries/WebPageMetadata/WebPageMetadata.delio.generated';

export const MetaTags = memo(
  ({
    canonicalPath,
    titleFallback,
    imageUrlFallback,
    descriptionFallback,
  }: MetaTagsProps) => {
    const { asPath } = useRouter();
    const { data } = useWebPageMetadataQuery({
      variables: { path: asPath?.split('?')[0] ?? '*' },
    });

    const doesExactPathMatch = data?.webPageMetadata?.path === asPath;

    const selectContent = (
      cmsMeta: string | undefined | null,
      propMeta: string | undefined | null,
      fallbackValue?: string
    ) => {
      // if the path from CMS matches the current path, data from CMS has the priority.
      // Otherwise, swap the order and prefer fallback metadata from props.
      const exactMatchOrder = [cmsMeta, propMeta, fallbackValue];
      const nonExactMatchOrder = [propMeta, cmsMeta, fallbackValue];
      const values = doesExactPathMatch ? exactMatchOrder : nonExactMatchOrder;
      return values.find(isTruthy) ?? fallbackValue;
    };

    const title = selectContent(
      data?.webPageMetadata?.title,
      titleFallback,
      defaultTitle
    );
    const description = selectContent(
      data?.webPageMetadata?.description,
      descriptionFallback,
      defaultDescription
    );
    const image = selectContent(
      data?.webPageMetadata?.ogImageUrl,
      imageUrlFallback
    );
    const canonicalUrl =
      canonicalPath &&
      new URL(canonicalPath, publicEnv.NEXT_PUBLIC_WEBSITE_URL).toString();

    return (
      <Head>
        <title key="title">{title}</title>
        <meta name="description" content={description} key="description" />
        <meta
          property="og:description"
          content={description}
          key="og:description"
        />
        <meta property="og:title" content={title} key="og:title" />
        <meta property="og:image" content={image} key="og:image" />
        <meta
          name="twitter:card"
          content="summary_large_image"
          key="twitter:card"
        />
        <meta
          name="twitter:site"
          content={delioTwitterUser}
          key="twitter:site"
        />
        <meta name="twitter:title" content={title} key="twitter:title" />
        <meta
          name="twitter:description"
          content={description}
          key="twitter:description"
        />
        <meta
          name="twitter:image"
          content={selectContent(
            data?.webPageMetadata?.twitterImageUrl ??
              data?.webPageMetadata?.ogImageUrl,
            imageUrlFallback
          )}
          key="twitter:image"
        />
        {canonicalPath && (
          <>
            <link rel="canonical" href={canonicalUrl} />
            <meta property="og:url" content={canonicalUrl} key="og:url" />
          </>
        )}
        {publicEnv.NEXT_PUBLIC_ENVIRONMENT === 'prod' && (
          <meta
            name="google-site-verification"
            content="CHK2JjIt3SrBi4JzSHLtsMPsYuPQ2Iwwt-RGOhtu0Uk"
          />
        )}
      </Head>
    );
  }
);

const defaultTitle =
  'delio supermarket online - dostawa do domu nawet w godzinę.';

const defaultDescription =
  'Zamów artykuły spożywcze, chemię do domu, owoce i wodę przez internet. Dostawa do domu nawet w godzinę.';

const delioTwitterUser = undefined;

type MetaTagsProps = {
  canonicalPath?: string;
  titleFallback?: string;
  descriptionFallback?: string;
  imageUrlFallback?: string;
};
