// All new things have to be in `extend` section, otherwise they will override default values
// For more info see: https://tailwindcss.com/docs/presets#theme

import { Config } from 'tailwindcss';

const config = {
  theme: {
    extend: {
      width: {
        4.5: '1.125rem',
      },
      height: {
        4.5: '1.125rem',
      },
      spacing: {
        4.5: '1.125rem',
        200: '0.5rem',
        750: '1.25rem',
        400: '1rem',
        150: '0.25rem',
      },
      zIndex: {
        'over-chatbot': '9999999999',
      },
      backgroundImage: {
        skeleton:
          'linear-gradient(90deg, #F4F4FB 8%, #D7D7DF 18%, #F4F4FB 33%)',
        'incomplete-progress-bar-gradient':
          'linear-gradient(90deg, #92A3FF 0%, #5E73E5 202.25%)',
        'complete-progress-bar-gradient':
          'linear-gradient(90deg, #51CB92 0%, #00523C 202.25%)',
      },
      screens: {
        'wide-s': '2000px',
      },
      colors: {
        custom: {
          modalBackdrop: 'rgba(0, 0, 10, 0.60)',
        },
        critical: {
          100: '#FCE3E3',
          700: '#991414',
        },
        current: 'currentColor',
        label: {
          multibuy: '#F5F8FC',
          'gluten-free': '#F5F0E8',
          vegan: '#ECF2E5',
          'sugar-free': '#E5ECF6',
          'lactose-free': '#F3E5F3',
          vegetarian: '#E2EFE5',
          local: '#FCF0E3',
          health: '#F3E6EC',
          new: '#EEE6E9',
          popular: '#F9EDE6',
          eco: '#E1F0EC',
          bio: '#E5EFE7',
        },
        badge: {
          multibuy: '#3F78BB',
          vegan: '#81A841',
          'sugar-free': '#6C9ACF',
          'lactose-free': '#CA71B7',
          vegetarian: '#58B060',
          local: '#E78D8D',
          health: '#CF8096',
          new: '#AA7575',
          popular: '#E08644',
          'gluten-free': '#C09E5E',
          eco: '#52BA8D',
          bio: '#6CAF68',
          surface: {
            zestaw: '#8964B8',
          },
        },
        decorative: {
          surface: {
            top: '#FAE579',
            two: '#E2EFE5',
            three: '#B64C9F',
            four: '#C4E4F0',
          },
        },
        text: {
          top: '#66480C',
          subtle: '#636373',
          subtlest: '#848496',
          default: '#29293D',
          highlighted: '#00523C',
          primary: '#25976D',
          disabled: '#A5A5B9',
          critical: '#CF3B3B',
        },
        border: {
          default: '#CCCCDB',
          hovered: '#196752',
          focused: '#196752',
          subtle: '#EEEEF6',
          info: {
            subdued: '#C4E4F0',
          },
          success: {
            subdued: '#C3DAD0',
          },
          active: '#51CB92',
        },
        surface: {
          top: '#A17505',
          success: {
            subdued: '#DDF0EA',
          },
          default: '#FFFFFF',
          subtle: '#FAFAFD',
          disabled: '#F4F4FB',
          hovered: '#F4F4FB',
          active: '#F3FAF8',
          warning: {
            subdued: '#FAF4D9',
          },
          neutral: {
            default: '#F4F4FB',
            hovered: '#EEEEF6',
          },
          critical: {
            subdued: '#FCE3E3',
          },
          info: {
            subdued: '#E7F7FD',
          },
          highlight: {
            default: '#002329',
          },
          brand: {
            'delio-night': '#2B5A86',
          },
        },
        action: {
          secondary: {
            default: '#F4F4FB',
          },
          primary: {
            default: '#25976D',
            hovered: '#196752',
            focused: '#C3DAD0',
            pressed: '#00523C',
            disabled: '#EEEEF6',
          },
          critical: {
            default: '#CF3B3B',
          },
          tertiary: {
            default: '#DDF0EA',
            pressed: '#DCEFE9',
            hovered: '#FAFAFD',
          },
        },
        grayscale: {
          0: '#FFFFFF',
          50: '#FAFAFD',
          300: '#CCCCDB',
          750: '#29293D',
        },
        yellow: {
          300: '#FAE579',
        },
        interactive: {
          default: '#25976D',
          hovered: '#196752',
          pressed: '#00523C',
        },
        icon: {
          info: ' #5E73E5',
          critical: '#CF3B3B',
          success: '#25976D',
          primary: '#FFFFFF',
          default: '#29293D',
          disabled: '#CCCCDB',
          warning: '#CCA109',
          highlight: '#25976D',
          nite: '#1E8AEE',
          subtlest: '#848496',
        },
        disabled: '#CCCCDB',
      },
      fontSize: {
        'title-sm': ['1.125rem', '1.5rem'],
        'title-md': ['1.25rem', '1.5rem'],
        'title-lg': ['1.5rem', '1.75rem'],
        'body-sm': ['0.75rem', '1rem'],
        'body-md': ['0.875rem', '1.25rem'],
        'body-lg': ['1rem', '1.25rem'],
        'label-lg': ['0.75rem', '1rem'],
        'heading-large': ['2rem', '2.5rem'],
        'heading-small': [
          '1.5rem',
          { lineHeight: '1.75rem', letterSpacing: '-0.2' },
        ],
        'heading-medium': ['1.75rem', '2rem'],
        'heading-xlarge': ['3.5rem', '4rem'],
        'caption-lg': ['0.75rem', '1rem'],
        'caption-sm': ['0.625rem', '0.75rem'],
        'heading-large-sm': ['2rem', '2.5rem'],
        'heading-large-md': ['2.5rem', '3rem'],
        'heading-large-lg': ['3em', '3.5rem'],
      },
      borderWidth: {
        1.5: '1.5px',
      },
      boxShadow: {
        'border-inset': 'inset 0 0 0px 1px var(--tw-shadow-color)',
        'border-inset-2': 'inset 0 0 0px 2px var(--tw-shadow-color)',
        borderFocused: '0px 0px 0px 4px #C3DAD0',
        borderFocusedSm: '0px 0px 0px 2px #C3DAD0',
        progressBar: '0px 1px 2px 0px rgba(0, 0, 10, 0.08) inset',
        upsellFooter:
          '0px -1px 2px 0px rgba(0, 0, 0, 0.06), 0px -1px 3px 0px rgba(0, 0, 0, 0.1)',
        progress:
          '0px 0px 0px 1px #EEEEF6 inset, 0px 1px 2px 0px rgba(0, 0, 0, 0.08) inset',
        upsellIndicator: '0px 2px 20px 0px rgba(33, 33, 45, 0.03)',
      },
      dropShadow: {
        'jush-ad': [
          '0px 4px 6px rgba(0, 0, 0, 0.05)',
          '0px 10px 15px rgba(0, 0, 0, 0.1)',
        ],
      },
      keyframes: {
        'accordion-down': {
          from: { height: '0' },
          to: { height: 'var(--radix-accordion-content-height)' },
        },
        'accordion-up': {
          from: { height: 'var(--radix-accordion-content-height)' },
          to: { height: '0' },
        },
        shine: {
          to: {
            'background-position-x': '-200%',
          },
        },
        'slide-in-right': {
          '0%': { transform: 'translateX(100%)' },
          '100%': { transform: 'translateX(0)' },
        },
        'slide-out-right': {
          '0%': { transform: 'translateX(0)' },
          '100%': { transform: 'translateX(100%)' },
        },
        'fade-in': {
          from: { opacity: '0' },
          to: { opacity: '1' },
        },
        'fade-out': {
          '0%': { opacity: '1' },
          '100%': { opacity: '0' },
        },
        circle: {
          from: { 'clip-path': 'circle(70%)' },
          to: { 'clip-path': 'circle(0%)' },
        },
        showBadge: {
          from: { width: '76px', height: '65px' },
          to: { width: '296px', height: '208px' },
        },
        'ad-expand': {
          '0%': {
            'clip-path': 'circle(70%)',
            transformOrigin: 'center',
            filter: 'none',
          },
          '25%': {
            'clip-path': 'circle(0)',
            transform: 'scale(0)',
            filter: 'none',
          },
          '50%': {
            'clip-path': 'circle(70%)',
            filter: 'none',
          },
          '75%': {
            transform: 'scale(3.75)',
            transformOrigin: 'left',
            filter: 'none',
          },
          '100%': {
            transform: 'scale(3.35)',
            transformOrigin: 'left',
            filter: 'revert-layer',
          },
        },
        'ad-collapse': {
          '0%': {
            transform: 'scale(3.35)',
            transformOrigin: 'left',
            filter: 'none',
          },
          '33%': {
            'clip-path': 'circle(70%)',
            transform: 'scale(0)',
            filter: 'none',
          },
          '66%': {
            'clip-path': 'circle(0)',
            transform: 'scale(0)',
            filter: 'none',
          },
          '100%': {
            transformOrigin: 'center',
            filter: 'revert-layer',
          },
        },
      },
      animation: {
        'accordion-down': 'accordion-down 0.2s ease-out',
        'accordion-up': 'accordion-up 0.2s ease-out',
        shine: ' 1.3s shine linear infinite',
        'ad-expand': 'ad-expand 1.1s ease-in forwards',
        'ad-collapse': 'ad-collapse 1.1s ease-in forwards',
        'slide-in-right': 'slide-in-right 0.3s ease-in-out',
        'slide-out-right': 'slide-out-right 0.3s ease-in-out',
        'fade-in': 'fade-in 300ms ease-in-out',
        'fade-out': 'fade-out 300ms ease-in-out',
      },
    },
  },
} satisfies Partial<Config>;

export { config };
